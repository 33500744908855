import React, { useEffect, useRef, useState } from 'react'
import borderWhiteImage from "../../assets/images/screen_2_back_outline.png"
import Swiper, { EffectFade, Pagination, Mousewheel } from "swiper";
import {useWheel} from "react-use-gesture"


Swiper.use([EffectFade, Pagination, Mousewheel]);
function SwiperScreen2({swiperMainRef}) {

    // States
    const [scollText, setScrollText] = useState('Scroll')
    const [enabled, setEnabled] = useState(true)
    const [swiperSlide, setSwipdeSlide] = useState(false)

    let animating = false

    // Slides Data
    const slideData = [
        {text:['mobux','is visual'], backImage:'bg-screen-2-back1'},
        {text:['we tell','stories'], backImage:'bg-screen-2-back2'},
        {text:['that','drive action'], backImage:'bg-screen-2-back3'},
        {text:['and get','engagement'], backImage:'bg-screen-2-back4'}
    ]

    // Refs 
    let swiperScreen2 = useRef(null)

    // Effects
    useEffect(() => {
        swiperScreen2.current = new Swiper('.swiper-container2', {
            spaceBetween: 30,
            effect: 'fade',
            direction:'vertical',
            mousewheel:true,
            nested: true,
            on:{
                transitionEnd:(e) =>{
                    if(e.activeIndex === 3){
                        setScrollText("Swipe Left")
                    }else{
                        setScrollText("Scroll")
                    }
                }
            }
        });
        swiperScreen2.current.init()
    }, [])

    useEffect(()=>{
        if(swiperSlide){
            swiperScreen2.current.slideNext(1000)
            setSwipdeSlide(false)
        }
    }, [swiperSlide])

    const wheel = useWheel((e) => {
        console.warn(e)
        
        if(e.first && e.direction[1] > 0){
            swiperScreen2.current.slideNext(1000)
        }

        if(e.first && e.direction[1] < 0){
            swiperScreen2.current.slidePrev(1000)
        }
    })

    return (
        <div className={`relative default-swiper-two-size bg-screen-2-back1 bg-cover sm:rounded-lg`}>
            <div 
            ref={ref => {swiperScreen2 = ref}}
            className="swiper-container2 w-full h-full">
                <div className="swiper-wrapper">
                    {slideData.map((slide, index) => (
                        <div key={index+slide.text} className={`swiper-slide default-swiper-two-size relative`}>
                            <div className={`w-full h-full ${slide.backImage} bg-cover bg-center relative`}>
                                    <img className="h-full w-full" src={borderWhiteImage} />   
                                    <div className="absolute bottom-12 sm:bottom-14 text-center w-full">
                                            <p className="uppercase text-white text-2xl font-muktamalar-light">{scollText}</p>
                                    </div>
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"> 
                                    {slide.text.map((text, i) =>(
                                        <p key={i} className="text-6xl mb-0 text-white font-muktamalar-bold">{text}</p>
                                        ))}
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SwiperScreen2
