import styled from "styled-components";


export const Wrapper = styled.section`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,0.7);
    z-index:104;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    // img{
    //     width:7%
    // }
    .pointer{
        margin-top: 3em; 
    }
    
    p{
        margin-top: 0.7em;
        font-style:italic;
        color:white;
        font-size:1.65em;
        text-align:center
    }
    @media(max-width:768px){
        // img{
        //  width:15%
        // }
    }
`