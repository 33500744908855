import SwiperScreen1 from "../screens/SwiperScreen1";
import SwiperScreen2 from "../screens/SwiperScreen2";
import SwiperScreen3 from "../screens/SwiperScreen3";
import SwiperScreen4 from "../screens/SwiperScreen4";
import SwiperScreen5 from "../screens/SwiperScreen5";
import SwiperScreen6 from "../screens/SwiperScreen6";


export const screens = [
    {
        title : "Screen 1",
        component: <SwiperScreen1/>
    },
    {
        title : "Screen 2",
        component: <SwiperScreen2/>
    },
    {
        title : "Screen 3",
        component: <SwiperScreen3/>
    },
    {
        title : "Screen 4",
        component: <SwiperScreen4/>
    },
    {
        title : "Screen 5",
        component: <SwiperScreen5/>
    },
    {
        title : "Screen 6",
        component: <SwiperScreen6/>
    }
]



export const carouselCardData = [
    {
        title: 'Basil Haydens',
        imgSrc: 'bg-bh-card',
        link: 'https://themobux.com/works/basilhaydens'
    },
    {
        title: `Willa's Kitchen`,
        imgSrc: 'bg-oat-milk-card',
        link: 'https://themobux.com/works/willaskitchen'
    },
    {
        title: 'House Beer',
        imgSrc: 'bg-house-beer-card',
        link: 'https://themobux.com/works/housebeer'
    },
    {
        title: 'Audemars Piguet',
        imgSrc: 'bg-ap-card',
        link: 'https://themobux.com/works/audemarspiguet'
    },
    {
        title: 'Legent',
        imgSrc: 'bg-legent-card',
        link: 'https://themobux.com/works/legent'
    },
    // {
    //     title: 'The Nou Project',
    //     imgSrc: 'bg-the-nou-project-card',
    //     link: 'https://flowco.io/TheNouProject'
    // },
    // {
    //     title: 'Wild For',
    //     imgSrc: 'bg-wild-for-card',
    //     link: 'https://themobux.com/works/willaskitchen'
    // },
    {
        title: 'Frome',
        imgSrc: 'bg-frome-card',
        link: 'https://themobux.com/works/frome'
    }
]