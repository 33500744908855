import './App.css';
import './globals.scss';
import CustomSwiper from './components/CustomSwiper';
import logo from "./assets/logos/mobUX_White_Green.png"
import { useEffect, useRef, useState } from 'react';
import { gsap, Power4 } from "gsap";
import ReactGa from 'react-ga'
// Ant Design
import 'antd/dist/antd.css';

function DesktopApp() {

  // State
  const [showSwiper, setShowSwipper] = useState(false)
  // Refs
  let mainImageRef = useRef(null)
  let mainWrapper = useRef(null)

  useEffect(()=>{

    gsap.to(mainImageRef, {
      duration: 2,
      opacity: 1,
      ease: Power4.easeInOut,
      delay: 0.2
    })

    gsap.to(mainWrapper, {
      duration: 1.35,
      backgroundColor: 'black',
      ease: Power4.easeIn,
      delay:2
    })
    gsap.to(mainImageRef, {
      duration: 1.35,
      transform: 'translateX(5000px) scale(100)',
      ease: Power4.easeIn,
      delay:2
    })
    gsap.to(mainImageRef, {
      duration: 1.5,
      opacity:0,
      ease: Power4.easeIn,
      delay:2.2
    }).then(()=>{
      setShowSwipper(true)
    })

  },[])

  return (
    <div ref={ref => {mainWrapper = ref}} className="w-screen h-screen bg-mobux-dark-purple flex flex-row items-center justify-center ">
        {showSwiper && <CustomSwiper /> }
      <img draggable={false} ref={ref => {mainImageRef = ref}} src={logo} className="absolute opacity-0 transform select-disable"   />
    </div>
  );
}


function MobileApp() {

  useEffect(() => {
    ReactGa.initialize("UA-164685403-5");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const [experienceEnded, setExperienceEnded] = useState('')

  return(
    <div className="w-screen h-full bg-black flex flex-row items-center justify-center ">
        <CustomSwiper 
          experienceEnded={experienceEnded} 
          setExperienceEnded={setExperienceEnded} 
        />
    </div>
  )
}

function App() {
  return <MobileApp />
  // window.innerWidth > 640 ?
  // <DesktopApp />
  // :
}

export default App;
