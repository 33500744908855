import styled from "styled-components";

let fromRight = document.getElementById("root").getBoundingClientRect().right
let rootWidth = document.getElementById("root").offsetWidth

fromRight = fromRight - rootWidth

export const Wrapper = styled.div`
 position:absolute;
 z-index:25;
 top:80%;
 right:${-120}px;
 width:120px;
 height:68px;
 background-color:black;
 border-radius:40px;
 display:flex;
 flex-direction:column;
 align-items:flex-start;
 justify-content:center;
 opacity:0;
 padding-left: 20px

`

export const ProgressText = styled.div`
    color:white;
    font-size:9px;
    font-weight:bold;
    margin:2px
`

export const ProgressCircle = styled.div`
    height:29%;
    border:1px solid white;
    width:30px;
    border-radius:10px;
    display:flex;
    align-items:center;
    justify-content:center;
`

export const ProgressContainer = styled.div`
    width:70%;
    height:3px;
    display:flex;
    justify-content:flex-end
`

export const ProgressBar = styled.div`
    width:3px;
    height:100%;
    border-radius:23px;
    background-color:gray;
    opacity:0;
    transition:1s;

    // animation: animate-progress cubic-bezier(0,1.6,.83,.67) 1s;
    animation-iteration-count: infinite;

    @keyframes animate-progress {
        0% {
          width:2px;
          opacity:1;
          background-color:white
        }
        100% {
            width:100%;
        }
`