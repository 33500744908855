import React from 'react'
import TitleContent from '../../components/TitleContent'
import laptopGif from "../../assets/images/gifs/Export_5_Info_Small_1.gif"

function SwiperScreen4() {
    return (
        <div className="h-full w-full bg-gradient-back bg-cover">
            <TitleContent 
            title={
                <>
                    <p className="mb-0 uppercase text-4xl">What you can <span className="text-mobux-green">measure</span></p>
                    <p className="mb-0 uppercase text-4xl">you can manage</p><br/>
                </>
            }
            content= {
                <>
                    <p className="mb-0">Gain customer insights </p>
                    <p className="mb-0">while building a database. Follow up</p>
                    <p className="mb-0">afterwards to continue the conversation.</p>
                </>
            }
            />
            <img alt="analytics iamge" width="80%" src={laptopGif} style={{top:'66%'}} className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"  />
        </div>
    )
}

export default SwiperScreen4
