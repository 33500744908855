import React from 'react'

function TitleContent({title, content, height}) {
    return (
        <section className="text-white text-center pt-20">
            <div className={`font-muktamalar-bold text-3xl md:text-4.5xl leading-none h-20 md:h-24`}>
                {title}
            </div>
            <div className="font-muktamalar-light text-xl md:text-2xl mt-1 leading-tight">
                {content}
            </div>
        </section>
    )
}

export default TitleContent
