import React, {useEffect,useRef} from 'react'
import { Wrapper,ProgressCircle,ProgressBar,ProgressContainer ,ProgressText} from './scrollIndicatorStyles'
import { gsap,Power2,Power4,TimelineMax } from "gsap";

export default function ScrollIndicator() {
    
    let scrollIndicator = useRef(null)
    let progressBar = useRef(null)

    let fromRight = document.getElementById("root").getBoundingClientRect().right
    let rootWidth = document.getElementById("root").offsetWidth

    fromRight = fromRight - rootWidth

    console.warn(fromRight)


    useEffect(()=>{

        gsap.to(scrollIndicator,{
            right:-55,
            opacity:1,
            ease:Power2.easeInOut,
            delay:4
        })

        gsap.to(scrollIndicator,{
            opacity:0,
            ease:Power4.easeInOut,
            delay:13
        })

    },[])

    useEffect(()=>{
        let tl = new TimelineMax({repeat:20})

        tl.to(progressBar,{
            opacity:"1",
            width:"20%",
            ease:Power4.easeInOut,
        })
        tl.to(progressBar,{
            width:"100%",
            backgroundColor:"white",
            duration:0.01,
        })
        tl.to(progressBar,{
            display:"none",
            ease:Power4.easeInOut,
            delay:0.5
        })
        tl.to(progressBar,{
            width:"2px",
            backgroundColor:"gray",
            ease:Power4.easeInOut,
        })
    },)

    return (
        <Wrapper ref={ref => {scrollIndicator = ref}} >
            <ProgressText >SWIPE</ProgressText>
            <ProgressCircle>
                <ProgressContainer>
                    <ProgressBar ref={ref => {progressBar = ref}} />
                </ProgressContainer>
            </ProgressCircle>
        </Wrapper>
    )
}
