import React from 'react'
import TitleContent from '../../components/TitleContent'
import progressBar from '../../assets/images/Export_4_Info_Small-–-6_GIF-2_NEW.gif'

function SwiperScreen3() {
    return (
        <div className="h-full w-full bg-purple-back bg-cover bg-center">
            <TitleContent 
            title={
                <>
                    <p className="mb-0 uppercase text-4xl">One experience, </p>
                    <p className="mb-0 uppercase text-mobux-green text-4xl">multiple channels</p><br/>
                </>
            }
            content= {
                <>
                    <p className="mb-0">mobUX can be activated on any</p>
                    <p className="mb-0">online or offline channel. Just like a</p>
                    <p className="mb-0">simple URL link!</p>
                </>
            }
            />
            <img alt="progress iamge" width="56%" src={progressBar} style={{top:'65%'}} className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"  />
        </div>
    )
}

export default SwiperScreen3
