import React, {useEffect, useRef, useState} from 'react'

// Swiper libraries
import Swiper, { Navigation, EffectCoverflow, EffectFade, Pagination } from "swiper";
import 'swiper/swiper-bundle.css';

// Gsap Animations library
import {TimelineMax, Power3, Power1 ,gsap, Power4} from 'gsap'

// Screens Data
import { screens } from '../../modules/screensData';

// Mobux Logo
import logo from "../../assets/logos/mobUX_White_Green.png"

// sass
import './customSwiperStyles.scss'
import InstructionOverlay from '../InstructionOverlay';
import ScrollIndicator from '../ScrollIndicator';
import SwiperScreen1 from '../../screens/SwiperScreen1';
import SwiperScreen2 from '../../screens/SwiperScreen2';
import SwiperScreen3 from '../../screens/SwiperScreen3';
import SwiperScreen4 from '../../screens/SwiperScreen4';
import SwiperScreen5 from '../../screens/SwiperScreen5';
import SwiperScreen6 from '../../screens/SwiperScreen6';
// import InstructionOverlay from '../InstructionOverlay';
import previewImage from '../../assets/images/Preview_Image_2.png'

import { MdClose } from "react-icons/md";

import ReactGa from 'react-ga'



Swiper.use([Navigation, EffectCoverflow, Pagination]);
function CustomSwiper({experienceEnded, setExperienceEnded}) {
    
    // States
    const [alowSNextSlide, setAllowSlideNext] = useState(true)
    const [alowPrevSlide, setAllowSlidePrev] = useState(true)
    const [startApp, setStartApp] = useState(false)
    const [ swiperActive ,setSwiperActive] = useState(false)
    const [refreshState, setRefreshState]= useState(false)
    const [showNavigation, setShowNavigation] = useState(false)
    const [instructionOverlay, setShowInstructionOverlay] = useState(false)
    const [showScrollIndicator, setShowScrollIndicator] = useState(false)
    const [iframeModalLink, setIframeModalLink] = useState('')

    // Refs
    let swiperRef = useRef(null)
    let splashLoaderHeaderRef = useRef(null)
    let splashLoaderTextOpacity = useRef(null)
    let swiperContainer = useRef(null)
    let swiperWrapper = useRef(null)


    useEffect(()=>{
        if(experienceEnded === 'ended'){
            ReactGa.event({
                category:"Navigation",
                action:"Subscribe - Reached Get in Touch Screen"
            })
        }
    },[experienceEnded])

    // Effects
    useEffect(()=>{
        swiperRef.current = new Swiper('.swiper-container', {
        speed: 400,
        spaceBetween: 50,
        // spaceBetween: 30,
        effect: 'coverflow',
        noSwipingClass: 'swiper-no-swiping-main',
          centeredSlides: true,
          slidesPerView: 1,
        //   grabCursor: true,
        //   observer: true, 
        //   observeParents: true,
        coverflowEffect: 
        {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows : true,
        },
        navigation:{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable:true,
        },
        on: {
            sliderMove : ()=> {
                setShowScrollIndicator(false)
              },
            slideChangeTransitionEnd: () => {
               setShowScrollIndicator(true)
            },
            slideChange: (data) => {
                let {activeIndex} = data
                setTimeout(()=>{
                    setShowScrollIndicator(true)
                },4000)
                if(activeIndex === 0){
                    document.getElementById("scroll-indicator-wrapper").style.display = "none"
                  }
                  if(activeIndex === 1){
                    document.getElementById("scroll-indicator-wrapper").style.display = "none"
                  }
                  if(activeIndex === 2){
                    document.getElementById("scroll-indicator-wrapper").style.display = "initial"
                  }
                  if(activeIndex === 3){
                    document.getElementById("scroll-indicator-wrapper").style.display = "initial"
                  }
                  if(activeIndex === 4){
                    document.getElementById("scroll-indicator-wrapper").style.display = "none"
                  }
                  if(activeIndex === 5){
                    document.getElementById("scroll-indicator-wrapper").style.display = "none"
                    setExperienceEnded('ended')
                }
            },
        }
      });
        swiperRef.current.init()
        setStartApp(true)
    },[])

    useEffect(()=>{
        if(startApp){
            let tl = new TimelineMax();

            gsap.to(swiperWrapper, {
                opacity:1,
                ease: Power4.easeIn,
            })

            gsap.to(splashLoaderHeaderRef, {
                duration: 1,
                marginBottom: 220,
                ease: Power3.easeInOut,
                delay:0.7
            }).then(()=>{
                tl.to(swiperContainer, {
                    duration: 1.2,
                    translateX: 0,
                    ease: Power3.easeInOut,
                    delay:0.4
                }).then(()=>{
                    setSwiperStatus(true)
                    setShowNavigation(true)
                    setShowInstructionOverlay(true)
                })
            })
            gsap.to(splashLoaderTextOpacity, {
                duration: .5,
                opacity: 1,
                ease: Power1.easeInOut,
                delay:1.2
            })
            
        }
    },[startApp])

    useEffect(()=>{
        setRefreshState(!refreshState)
    },[swiperActive])

    useEffect(()=>{
        window.addEventListener('keydown', keyListener)
        return () => window.removeEventListener('keydown', keyListener)
    },[])


    // Functions
    const setSwiperStatus = (status) => setSwiperActive(status)

    const keyListener = (e) => {
        e.key === 'ArrowRight' && swiperRef.current.slideNext()
        e.key === 'ArrowLeft' && swiperRef.current.slidePrev()
    }


    // Data
    const screens = [
        {
            title : "Screen 1",
            component: <SwiperScreen1/>
        },
        {
            title : "Screen 2",
            component: <SwiperScreen2 swiperMainRef={swiperRef}/>
        },
        {
            title : "Screen 3",
            component: <SwiperScreen3/>
        },
        {
            title : "Screen 4",
            component: <SwiperScreen4/>
        },
        {
            title : "Screen 5",
            component: <SwiperScreen5 
            setIframeModalLink={(val) => setIframeModalLink(val)}
            />
        },
        {
            title : "Screen 6",
            component: <SwiperScreen6/>
        }
    ]


    // Components
    const splashLoader = () => (
        <div className="w-full h-full sm:rounded-lg relative bg-mobux-purple flex items-center justify-center">
            <img 
            ref={(ref) => {splashLoaderHeaderRef = ref;}} 
            src={logo} width="70%"
            // className="border-l-8 pl-3 border-mobux-green"
            />
            <p 
            ref={(ref) => {splashLoaderTextOpacity = ref;}}
            className="absolute text-white top-1/2 left-1/2 opacity-0 transform text-3xl -translate-x-1/2 -translate-y-1/2">
                Micro  Moments.
                <br/>
                Lasting Impacts.
            </p>
        </div>
    )

    return (
        <div className="relative default-size">
            <div 
            ref={ref => {swiperWrapper = ref}}
            className="opacity-0 default-size relative overflow-hidden z-10 sm:rounded-lg bg-white">
                <div className="absolute top-0 sm:w-100 sm:h-140 w-full h-full">
                    {splashLoader()}
                </div>
                <div 
                style={{transform: "translateX(100%)"}}
                ref={ref => {swiperContainer = ref}} 
                className="w-full h-full bg-black">
                    <div className="default-inner-size">
                        <div className="swiper-container sm:rounded-lg" >
                            <div className="swiper-wrapper sm:rounded-lg">
                                {screens.map((data, i)=>(
                                    <div key={data.title+i} className=" h-full w-full swiper-slide">
                                        {data.component}
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div style={{opacity: showNavigation ? 1 : 0, transition:'1s'}}>
                <div onClick={() => setShowScrollIndicator(false)} className="swiper-button-next"></div>
                <div onClick={() => setShowScrollIndicator(false)} className="swiper-button-prev"></div>
            </div>

            { instructionOverlay && <InstructionOverlay setShowInstructionOverlay={(status) => {
                setShowInstructionOverlay(status)
                setShowScrollIndicator(true)
                }} />}
            <div id="scroll-indicator-wrapper">
                {showScrollIndicator && <ScrollIndicator />}
            </div>
            <img src={previewImage} className="hidden"  />

            {iframeModalLink && <div className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70 w-full h-full flex items-center justify-center z-50">
                <div className="default-size relative flex items-center justify-center">
                    <p onClick={()=> setIframeModalLink('')} className='absolute top-4 text-lg left-3 z-10 text-white border-2 font-medium p-3 cursor-pointer rounded-full'>
                        <MdClose className="text-white" size={25} />
                    </p>
                    {iframeModalLink === 'Legent' &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/legent/'} ></iframe>}
                    {iframeModalLink === 'Basil Haydens' &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/basilhaydens/'} ></iframe>}
                    {iframeModalLink === `Willa's Kitchen` &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/willaskitchen/'} ></iframe>}
                    {iframeModalLink === 'House Beer' &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/housebeer/'} ></iframe>}
                    {iframeModalLink === 'Audemars Piguet' &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/audemarspiguet/'} ></iframe>}
                    {iframeModalLink === 'Frome' &&
                    <iframe  height="90%" width="85%" className="bg-black" src={'https://themobux.com/works/frome/'} ></iframe>}
                </div>
            </div>}
                   

        </div>

    )
}

export default CustomSwiper
