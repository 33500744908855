import React from 'react'
import TitleContent from '../../components/TitleContent'
import whiteSlice from '../../assets/images/white-slice.png'
import mobileImage from '../../assets/images/mobile_image_2.png'
import "./swiperScreen1.scss"

function SwiperScreen1() {
    return (
        <div className="h-full w-full bg-purple-back sm:rounded-lg relative" >
            <TitleContent 
            title={
                <>
                    <span className="text-primary-light-green uppercase text-4xl">Mobile </span>
                    <span className="uppercase text-4xl">content</span>
                    <p className="uppercase text-4xl">that drives action</p>
                </>
            }
            content= {
                <>
                    <p className="mb-0">We build custom high-impact mobile</p>
                    <p className="mb-0">experiences that engages and</p>
                    <p className="mb-0">converts in under a day.</p>
                </>
            }
            />
            <img className="absolute top-44" src={whiteSlice} />
            <img className="absolute custom-mobile bottom-0  left-1/2 transform -translate-x-1/2" src={mobileImage} />
        </div>
    )
}

export default SwiperScreen1
