import React, { useState, useEffect } from 'react'
import TitleContent from '../../components/TitleContent'
import mobuxLogo from '../../assets/images/gifs/Mobux_White_on_Purple.gif'
import validator from "validator"
import {FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaLink} from 'react-icons/fa'
import worldIcon from '../../assets/logos/Social-Media-Icons–5.png'
import { message } from 'antd'
import { lettersWithSpacesRegex } from '../../lib/validations'

function SwiperScreen6() {
    // States
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [errors, setErros] = useState([])
    const [formSubmitted, setFormSubmitted] = useState(false)

    // functions
    const handleEvent = (e) => {
        if(e.key === "Enter"){
            alert("enter")
        }
    }

    // Effects
    useEffect(() => {
        let errorCopy = errors
        if(email && !validator.isEmail(email)){
            errorCopy.email = "Please provide a valid email"
        }else{
            delete errorCopy.email
        }
        if(name && !lettersWithSpacesRegex.test(name)){
            errorCopy.name = "Please provide your name (Letter Only)"
        }else{
            delete errorCopy.name
        }
        setErros(errorCopy)
        
    }, [name, email])

    const testError = () => {
        let  error = false
        let errorCopy = errors

        if(!validator.isEmail(email)){
            errorCopy.email = "Please provide a valid email"
            error=true
        }else{
            delete errorCopy.email
        }
        if(!lettersWithSpacesRegex.test(name)){
            errorCopy.name = "Please provide you name (Letter Only)"
            error = true
        }else{
            delete errorCopy.name
        }
        return error
    }

    return (
        <div className="h-full w-full relative bg-gradient-back bg-cover">
                <>
                    <img src={mobuxLogo} width="75%" className="mx-auto" />
                </>
               { 
               formSubmitted ? 
               <div 
                className="absolute top-1/2 left-1/2 text-white text-4xl
                transform -translate-x-1/2 -translate-y-1/2 w-full text-center
                uppercase font-muktamalar-bold
                ">
                   Thanks! We'll be
                   <br/>
                   in touch soon
                </div>
               :
               <>
               <>
                    <p className="font-muktamalar-bold text-3xl -mt-5 text-center text-white uppercase">Get in touch</p>
                </>
                <form autoComplete="off" onSubmit={(e)=> {
                    e.preventDefault()
                    const hubsUrl = "https://api.hsforms.com/submissions/v3/integration/submit/6895664/61788479-bb0b-4836-b3cf-fa24c7d8c553";  
                    let url = 'https://mighty-crag-96434.herokuapp.com/customers';
                    let formObj = {
                        "fields": [
                            {
                                name: 'firstname',
                                value: name
                            },
                            {
                                name: 'lastname',
                                value: name
                            },
                            {
                                name: 'email',
                                value: email
                            },
                            {
                                name: 'phone',
                                value: '3453333333'
                            },
                            {
                                name: 'company',
                                value: "Some value"
                            }
                        ],
                        "url": [
                            hubsUrl
                        ]
                    }

                    if(!testError()){
                        
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(formObj)
                        }).then((response) => {
                            if(response.ok){
                                console.log(response, 'heres the response')
                                setFormSubmitted(true)
                            }
                            setEmail('')
                            setName('')
                        }).catch((error) => {
                            if(error){
                                alert("Error submitting")
                                console.error('there was an error!')
                                console.error('error', error);
                                message.error({
                                    content: `Oops!, Something went wrong, please try again.`
                                })
                            }
                        })
                    }

                }}>
                    <div className="w-10/12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="flex border-white border-b">
                                <label className="text-2xl text-white pb-1">Name:</label> 
                                <input onChange={(e)=> setName(e.target.value)} className="swiper-no-swiping-main w-full outline-none p-0 pl-5 text-white text-lg" style={{backgroundColor:'transparent'}} />
                            </div>
                            <p className="text-red-600 pt-2">{errors.name}</p>
                            <div className="flex border-white border-b mt-12">
                                <label className="text-2xl text-white pb-1 mt-1">Email:</label> 
                                <input onChange={(e)=> setEmail(e.target.value)} className="swiper-no-swiping-main w-full outline-none pl-5 text-white text-lg" style={{backgroundColor:'transparent'}} />
                            </div>
                            <p className="text-red-700 pt-2">{errors.email}</p>
                    </div>
                    <button type="submit" style={{top:"67%"}} 
                    className="px-8 py-2 cursor-pointer bg-white bg-opacity-0 handle-font hover:bg-opacity-5 transition text-white font-muktamalar-bold text-xl rounded-lg border border-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        SUBMIT NOW
                    </button>
                </form>
                </>
                }
                <div className="absolute w-full bottom-7 text-center">
                    <div className="flex justify-center mb-7">
                        <a href="https://themobux.com">
                            <img src={worldIcon} style={{width:39.5}} className="mx-5 text-white hover:text-blue-400" />
                        </a>
                        {/* <a href="">
                            <FaFacebookF  className="mx-5 text-white hover:text-blue-400" size={32} />
                        </a> */}
                        <a href="">
                            <FaTwitter  className="mx-5 text-white hover:text-blue-400" size={35}/>
                        </a>
                        <a href="">
                            <FaInstagram  className="mx-5 text-white hover:text-blue-400" size={35}/>
                        </a>
                        {/* <a href="">
                            <FaLinkedinIn  className="mx-5 text-white hover:text-blue-400" size={35}/>
                        </a> */}
                    </div>
                    {/* <a className="text-2xl text-white underline" href="">https://themobux.com</a> */}
                </div>
        </div>
    )
}

export default SwiperScreen6
