
import React from 'react'
import { Wrapper } from "./instructionOverlayStyles";
import mouseIcon from '../../assets/images/mouse_pointer.png'
import rightArrow from '../../assets/images/right-arrow.png'
import touchIcon from '../../assets/images/touch_icon.png'

import swipelast from "../../assets/images/swipe-last@2x.png";

export default function InstructionOverlay({setShowInstructionOverlay}) {
    return (
        <Wrapper onClick={()=> setShowInstructionOverlay(false)}>
            <div className='flex items-center justify-center w-full'>     
                <img width={70} className='hidden md:block mx-1' src={mouseIcon} />
                <img width={70} className="hidden md:block mx-1"  src={rightArrow} />
                <img  width={70} className="md:hidden" src={touchIcon}  />
            </div>
            <p className="md:hidden">Swipe to navigate</p>
            <p className="hidden md:block">Swipe or Click arrows to navigate</p>
        </Wrapper>
    )
}