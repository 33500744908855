import React, { useEffect, useRef, useState } from 'react'
import TitleContent from '../../components/TitleContent'
import Swiper, { Navigation, EffectCoverflow, EffectFade, Pagination } from "swiper";
import { carouselCardData } from '../../modules/screensData';
import ReactGa from 'react-ga'


Swiper.use([EffectCoverflow]);
function SwiperScreen5(props) {

    // states
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0)

    // Refs
    let carouselSwiper = useRef(null)


    let carouselSlidesIndex = [
        '0','1','2','3','4','5','6'
    ]

    // Effects
    useEffect(() => {
        carouselSwiper.current = new Swiper('.swiper-container-carousel', {
            effect: 'coverflow',
            centeredSlides: true,
            slidesPerView: 2,
            spaceBetween:30,
            //   grabCursor: true,
            //   observer: true, 
            //   observeParents: true,
            coverflowEffect: 
            {
            rotate: 0,
            stretch: 10,
            depth: -40,
            modifier: 1,
            slideShadows : true,
            },
            // freeMode: true,
            // freeModeMomentum: false
            on:{
                slideChange:((swiper) => {
                    console.warn('swiper')
                    console.warn(swiper)
                    setCarouselActiveIndex(swiper.activeIndex)
                    // carouselSlidesIndex.forEach(index => {
                        // if(index == swiper.activeIndex){
                            document.getElementById(`carousel-slide-${swiper.activeIndex}`).style.transform = "translateY(30px)"
                        // }
                        // else{
                        //     document.getElementById(`carousel-slide-${index}`).style.transform = "translateY(0px)"
                        // }  

                    // })
                }),
                touchEnd: (swiper) => {
                    setTimeout(()=>{
                        document.getElementById(`carousel-slide-${swiper.activeIndex}`).style.transform = "translateY(30px)"
                    },10)
                }
            }
        });
        carouselSwiper.current.init()
        document.getElementById(`carousel-slide-0`).style.transform = "translateY(30px)"
    }, [])

    return (
        <div className="h-full w-full overflow-hidden relative bg-gradient-back bg-cover">

            <TitleContent 
            title={
                <>
                    <p className="mb-0 uppercase text-4xl">Make every engagement</p>
                    <span className="text-4xl">an</span>
                    <span className="text-mobux-green uppercase text-4xl"> experience</span>
                </>
            }
            content= {
                <>
                    <p className="mb-0 text-1.35rem">Custom experiences built in a bite-size story</p>
                    <p className="mb-0 text-1.35rem">format, delivering the user experience of an</p>
                    <p className="mb-0 text-1.35rem">app with the ease of access of a website.</p>
                </>
            }
            />

            

            <div className="swiper-no-swiping-main ">
                <div className="mt-12 sm:mt-5 w-full h-80 md:h-86 py-2" >
                    <div className="swiper-container-carousel rounded-lg">
                        <div className="swiper-wrapper rounded-lg">
                            {carouselCardData.map((data, i) =>(<div 
                            style={{boxShadow:'2px 2px 12px black'}}
                            id={`carousel-slide-${i}`} className="swiper-slide bg-transparent rounded-md text-red duration-100">
                                <a target="_blank" >
                                    <div onClick={()=> 
                                    {
                                        props.setIframeModalLink(data.title)
                                        ReactGa.event({
                                            category:"Button",
                                            action:`Screen 5 - ${data.title} Card Clicked`
                                        })
                                    }
                                } 
                                        className={`w-full h-full ${data.imgSrc} bg-cover bg-center bg-transparent m-auto rounded-md relative`}>
                                        <button 
                                            className={`w-24 absolute left-1/2 bottom-4
                                            hover:scale-105 transition duration-300
                                            transform  -translate-x-1/2
                                            h-8 text-white
                                            rounded
                                            text-lg bg-opacity-75
                                            bg-custom-grey 
                                             ${carouselActiveIndex === i ? 'opacity-100' : 'opacity-0'}
                                            `}
                                        >
                                            View
                                        </button>
                                    </div>
                                </a>
                            </div>))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full text-center absolute bottom-5">
                <a href="https://themobux.com" 
                onClick={()=> ReactGa.event({
                    category:"Button",
                    action:"Screen 5 - Website link clicked"
                })}
                className="text-lg text-white underline">
                    View more on our website
                </a>
            </div>
            
        </div>
    )
}

export default SwiperScreen5
